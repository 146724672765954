import React, { useState, Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { Carousel } from 'antd';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import api from '../../api/api'

export default function Home() {
  const navigate = useNavigate()
  const [advantageList] = useState([
    {
      id: '1',
      title: '省钱省心',
      text: '银行贷款利率较低。这类小额贷款产品不仅满足了有资金需求的客户，也减低了客户还款的压力。',
      url: require('./imgs/advantage-pic1.png')
    },
    {
      id: '2',
      title: '极速服务',
      text: 'VIP贷款咨询通道，1分钟提交申请，5分钟匹配产品，一站式金融服务平台。',
      url: require('./imgs/advantage-pic2.png')
    },
    {
      id: '3',
      title: '还款灵活',
      text: '根据银行贷款利率以及贷款者的还贷能力，贷款者可以自由选择合适的还款方式，而且我们的专业顾问还会给出一些方案供客户参考。',
      url: require('./imgs/advantage-pic3.png')
    },
  ]);
  const caseList = [
    {
      id: '1',
      title: '信用贷',
      text: '林先生非常信任我们公司，之前就在公司申请过信用贷款，成功审批通过2W。但因当时资金问题就没得到全面解决，后面又因家中急事继续用钱就在第一时间又找到了我们浙理融。而我们通过客户情况直接推断出最适合客户的产品，在当天进件后第二天就成功放款10W，及时解决了客户需求，为此客户向我们表示多次感谢，且在当天晚上特意过来公司进行费用的结算。',
      url: require('./imgs/case-pic1.png')
    },
    {
      id: '2',
      title: '房抵贷',
      text: '秦先生想自己经营一家礼品店，但是启动资金还需要50万左右，秦先生在杭州临平有一套140平的全款住房，打算用这套房产申请房产抵押贷款。秦先生通过浙理融贷款网找到我为他办理，在短短一周后，他已经成功拿到所需的50万启动资金。',
      url: require('./imgs/case-pic2.png')
    },
    {
      id: '3',
      title: '企业贷',
      text: 'A公司是为某知名房地产企业提供原材料的企业，长期合作。今因为房地产公司的楼盘投资，资金周转不过来，需要晚2个月向A公司支付原材料款。但是A公司因为另一公司的材料需要，需投入资金周转。通过贷款网了解到浙理融。在与浙理融洽谈下，浙理融给予500万元的产业链融资授信额度，用于补充A公司的流动资金。',
      url: require('./imgs/case-pic3.png')
    },
  ]
  const raiders = [
    {
      id: '1',
      type: '信用贷',
      type_e: 'CREDIT LOAN',
      title: '电话核实3细节 贷款审批被拒的硬伤！',
      text: '一、留填电话不实，留填电话的举动虽小，但影响甚大，千万马虎不得，否则贷款结果很可能无疾而终。二、核实内容糟糕，留填电话只是个开始，谈话内容才称得上是绝对的重头戏。三、态度傲慢无礼，为了确保你在贷款申请表上留填的信息全部真实，贷款用途、工作单位、工作年限、身份证号、生肖属性等信息，信审会重新问过，此时你不仅需要完整无误地做出正确回答，更是要用态度说话，证明自己是个友善亲和的人。反之亦然，若是你用一副傲慢无礼的态度视人，恐怕多半会因看着不顺眼等诸如此类的原因，被信审宣判出局。',
      url: require('./imgs/raiders-pic1.png')
    },
    {
      id: '2',
      type: '房抵贷',
      type_e: 'MORTGAGE',
      title: '头顶4大光环 易开启银行贷款审批之门',
      text: '一、工作稳定。要想博得银行的喜爱，身上一定少不了工作踏实的闪光点。二、信用良好。不少人认为和银行保持距离，不发生借贷关系，便是最稳妥的借贷方式，届时，只要其他方面满足了申请条件，贷款资金便能轻松收入囊中。三、借款用途合理。四、颇有经济实力。',
      url: require('./imgs/raiders-pic2.png')
    },
    {
      id: '3',
      type: '车抵贷',
      type_e: 'CAR LOAN',
      title: '6个贷款常见问题你都知道吗？',
      text: '一、常见的贷款机构有哪些？二、贷款利息跟哪些因素有关？三、贷款额度通常为多少？四、贷款期限为多久？五、贷款常见申请材料有哪些？六、材料交齐后多久可以放款？',
      url: require('./imgs/raiders-pic3.png')
    },
    {
      id: '4',
      type: '企业贷',
      type_e: 'BUSINESS LOAN',
      title: '贷款如何避免操作不当给自己造成资金损失',
      text: '目前有银行等大型的金融贷款机构，也有小额贷款公司，网络贷款平台等。因利率优惠，监管严格，正常大家都会优先考虑银行等大型贷款机构。但如果信用出现过问题，则很难在这些机构贷到款，就需要考虑贷款公司或中介公司。',
      url: require('./imgs/raiders-pic4.png')
    },
  ]
  const [raidersNum, setRaidersNum] = useState(3)
  const [newList, setNewList] = useState([])
  useEffect(()=> {
    api.newsList({
        page: 1,
        num: 3
    }).then(res => {
        const dataList = res.data.data
        setNewList(dataList)
    })
  }, [])

  const toForm = (e) => {
    if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
    navigate('/form', {
        state: {
            isClient: true
        }
    })
  }

  const toCase = () => {
      navigate('/case')
  }

  // 跳转详情页
  const toDetails = (num) => {
    navigate('/news/details', {
        state: {
            id: num
        }
    })
  }

  const move = (index) => {
    setRaidersNum(index)
  }

  return (
    <div className='home'>
      <div className="banner" onClick={toForm}>
        <Header></Header>
        <img src={require('./imgs/banner.png')} alt="" className='banner-pic'/>
      </div>
      {/* 关于 */}
      <div className="about">
        <div className="bg"></div>
        <div className="content">
          <div className="title">
            <span className='title-box'>
                About 
                <div className="box">
                    <div></div>
                    <div></div>
                </div>
            </span>
          </div>
          <div className="title-s">关于浙理融</div>
          <div className="text">
              <div className="left">
                  <img src={require('./imgs/about-pic.png')} alt="" />
              </div>
              <div className="right">
                  <div className="label">
                      ABOUT
                  </div>
                  <h2>公司愿景</h2>
                  <p>以“竭尽全力创造客户认可的有价值的产品和服务”为使命，以“创新寻求发展，以发展带动创新”的为导向。</p>
                  <div className="btn" onClick={toForm}>
                      立即咨询 <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
                  </div>
                  <div className="toAbout">
                    <img src={require('./imgs/arrow.png')} alt="" />
                    关于我们
                  </div>
              </div>
          </div>
        </div>
      </div>
      {/* 优势 */}
      <div className="advantage">
        <div className="content">
          <div className="title">
            <span className='title-box'>
                Industry advantage
                <div className="box">
                    <div></div>
                    <div></div>
                </div>
            </span>
          </div>
          <div className="title-s">行业优势</div>
          <ul>
            {
              advantageList.map(item => {
                return (
                  <li key={item.id}>
                    <div className="left">
                      <img src={require('./imgs/douhao.png')} alt=""  className='douhao'/>
                      <h2>{item.title}</h2>
                      <p>{item.text}</p>
                    </div>
                    <div className="right">
                      <img src={item.url} alt="" />
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <div className="btn-box">
            <div className="btn" onClick={toForm}>
                立即咨询 <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* 经典案例 */}
      <div className="case-box">
        <div className="case">
          <Carousel 
          // autoplay
          className='caseItems'
          dots={{ className: 'case_dots' }}>
            {
              caseList.map(item => {
                return (
                  <Fragment key={item.id}>
                    <div className='caseItem'>
                      <div className="left">
                        <div className="title">
                          <span className='title-box'>
                              Classic Case 
                              <div className="box">
                                  <div></div>
                                  <div></div>
                              </div>
                          </span>
                        </div>
                        <span className="title-s">经典案例</span>
                        <h2>{item.title}</h2>
                        <p>{item.text}</p>
                        <div className="btns">
                          <div className="btn" onClick={toCase}>查看详情</div>
                          <div className="btn" onClick={toForm}>立即咨询</div>
                        </div>
                      </div>
                      <div className="right">
                        <img src={item.url} alt="" />
                      </div>
                    </div>
                  </Fragment>
                )
              })
            }
          </Carousel>
        </div>
      </div>
      {/* 服务保障 */}
      <div className="serve">
        <div className="bg"></div>
        <div className="content">
          <div className="title">
            <span className='title-box'>
            Service Assurance
                <div className="box">
                    <div></div>
                    <div></div>
                </div>
            </span>
          </div>
          <span className="title-s">服务保障</span>
          <ul>
            <li>
              <div className="yuan">
                <img src={require('./imgs/serve-pic1.png')} alt="" />
              </div>
              <div className="text">
                <h2>专业客服</h2>
                <p>7*24小时一对一全程在线服务</p>
              </div>
            </li>
            <li>
              <div className="yuan">
                <img src={require('./imgs/serve-pic2.png')} alt="" />
              </div>
              <div className="text">
                <h2>一站式服务</h2>
                <p>一站式的融资服务省心省力</p>
              </div>
            </li>
            <li>
              <div className="yuan">
                <img src={require('./imgs/serve-pic3.png')} alt="" />
              </div>
              <div className="text">
                <h2>智能推荐</h2>
                <p>在线测评匹配融资产品</p>
              </div>
            </li>
            <li>
              <div className="yuan">
                <img src={require('./imgs/serve-pic4.png')} alt="" />
              </div>
              <div className="text">
                <h2>实时跟进</h2>
                <p>时刻帮您跟进放款时间</p>
              </div>
            </li>
          </ul>
          <div className="btn-box">
            <div className="btn" onClick={toForm}>
              立即咨询
              <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* 融资攻略 */}
      <div className="raiders">
        <div className="content">
          <div className="title">
            <span className='title-box'>
              Financing Strategy
              <div className="box">
                  <div></div>
                  <div></div>
              </div>
            </span>
          </div>
          <span className="title-s">融资攻略</span>
          <ul>
            {
              raiders.map((item, index)=>{
                return (
                  <li key={item.id} 
                  className={index === raidersNum ? 'big' : ''} 
                  onMouseEnter={v=> move(index)}
                  style={{backgroundImage: `url(${item.url})`}}>
                    <div className="text-s">
                      <h2>{item.type_e}</h2>
                      <p>{item.type}</p>
                    </div>
                    <div className="text-b">
                      <h2>{item.type}</h2>
                      <h3>{item.title}</h3>
                      <p>{item.text}</p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
          <div className="btn-box">
            <div className="btn" onClick={toForm}>
              立即咨询
              <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* 新闻 */}
      <div className='news'>
        <div className="content">
          <div className="title">
            <span className='title-box'>
              News
                <div className="box">
                    <div></div>
                    <div></div>
                </div>
            </span>
          </div>
          <div className="title-s">新闻资讯</div>
          <ul>
            {
              newList.map(item=> {
                return (
                  <li key={item.id}>
                    <div className="pic">
                      <img src={item.images} alt="" />
                    </div>
                    <div className="text">
                      <div className='left'>
                        <h2>{item.title}</h2>
                        <p>{item.describe}...</p>
                        <span className="date">{item.update_time}</span>
                      </div>
                      <div className="btn" onClick={v => toDetails(item.id)}>
                        <img src={require('../../assect/imgs/arrow_right.png')} alt="" />
                      </div>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
      {/* 客户分布 */}
      <div className="distributed">
        <div className="content">
          <div className="title">
            <span className='title-box'>
              Distribution Of Customer
              <div className="box">
                  <div></div>
                  <div></div>
              </div>
            </span>
          </div>
          <span className="title-s">客户分布</span>
          <ul className="number">
            <li>
              <h2>全国各地</h2>
              <span>分布城市</span>
            </li>
            <li>
              <h2>200000+</h2>
              <span>全国客户</span>
            </li>
            <li>
              <h2>500+</h2>
              <span>每日新增客户</span>
            </li>
          </ul>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )

  
}
