import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import './index.scss'
import Header from '../../components/Header'
import Footer from '../../components/Footer'

export default function Case() {
  const navigate = useNavigate()
  const [cases] = useState([
    {
      num: '01',
      url: require('./imgs/case1.png'),
      title: '信用贷案例',
      text: '林先生非常信任我们公司，之前就在公司申请过信用贷款，成功审批通过2W。但因当时资金问题就没得到全面解决，后面又因家中急事继续用钱就在第一时间又找到了我们浙理融。而我们通过客户情况直接推断出最适合客户的产品，在当天进件后第二天就成功放款10W，及时解决了客户需求，为此客户向我们表示多次感谢，且在当天晚上特意过来公司进行费用的结算。'
    },
    {
      num: '02',
      url: require('./imgs/case2.png'),
      title: '房抵贷案例',
      text: '秦先生想自己经营一家礼品店，但是启动资金还需要50万左右，秦先生在杭州临平有一套140平的全款住房，打算用这套房产申请房产抵押贷款。秦先生通过浙理融贷款网找到我为他办理，在短短一周后，他已经成功拿到所需的50万启动资金。'
    },
    {
      num: '03',
      url: require('./imgs/case3.png'),
      title: '企业贷案例',
      text: '黄先生所在的A公司是为某知名房地产企业提供原材料的企业，长期合作。今因为房地产公司的楼盘投资，资金周转不过来，需要晚2个月向A公司支付原材料款。但是A公司因为另一公司的材料需要，需投入资金周转。通过贷款网了解到浙理融。在与浙理融洽谈下，浙理融给予500万元的产业链融资授信额度，用于补充A公司的流动资金。'
    },
    {
      num: '04',
      url: require('./imgs/case4.png'),
      title: '车抵贷案例',
      text: '白先生是一家食品加工厂的老板，生意一直不错。因为生意需要，想多投资一些新厂，扩大经营规模，想用自己名下的一款车抵押贷款30万，但是一直没有门路。后来经过朋友介绍找到我们帮助其办理贷款。我们的专业服务取得了白先生的信任。双方合作很愉快！'
    }
  ])
  const toForm = (e) => {
    if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
    navigate('/form', {
        state: {
            isClient: true
        }
    })
  }
  return (
    <div className='case'>
      <div className="banner" onClick={toForm}>
        <Header></Header>
        <img src={require('./imgs/banner.png')} alt="" className='banner-pic' />
      </div>
      <ul className="content">
        {
          cases.map((item, index)=> {
            if(index%2) {
              return (
                <li key={item.num}>
                  <div className="text">
                    <div className="label">
                      <div className='title-box'>
                        案例{item.num}
                        <div className="box">
                            <div></div>
                            <div></div>
                        </div>
                      </div>
                    </div>
                    <h2>{item.title}</h2>
                    <p>{item.text}</p>
                    <div className="btn" onClick={toForm}>立即咨询</div>
                  </div>
                  <img src={item.url} alt="" />
                </li>
              )
            } else {
              return (
                <li key={item.num}>
                  <img src={item.url} alt="" />
                  <div className="text">
                    <div className="label">
                      <div className='title-box'>
                        案例{item.num}
                        <div className="box">
                            <div></div>
                            <div></div>
                        </div>
                      </div>
                    </div>
                    <h2>{item.title}</h2>
                    <p>{item.text}</p>
                    <div className="btn" onClick={toForm}>立即咨询</div>
                  </div>
                </li>
              )
            }
          })
        }
      </ul>
      <Footer></Footer>
    </div>
  )
}
