import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import style from './index.module.scss'
import api from '../../api/api'

export default function Footer() {
    const navigate = useNavigate()
    const [ newList, setNewList ] = useState([])

    useEffect(()=> {
        getNewList()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // 获取新闻列表
    const getNewList = () => {
        api.newsList({
            page: 1,
            num: 4
        }).then(res => {
            const dataList = res.data.data
            setNewList(dataList)
        })
    }
    const toDetails = (num) => {
        navigate('/news/details', {
            state: {
                id: num
            }
        })
    }
    const toCaseStudy = () => {
        navigate('/case')
    }
    const toAbout = () => {
        navigate('/aboutUs')
    }
    const toForm = () => {
        navigate('/form', {
            state: {
                isClient: true
            }
        })
    }
  return (
    <div className={style.footer}>
        <div className={style.content}>
            <div className={style.hotline}>
                <h5>咨询热线</h5>
                <div className={style.num}>
                    <img src={require('./imgs/phone.png')} alt="" />
                    <span>0571-85096707</span>
                </div>
                <p>地址：浙江省杭州市上城区万象城2幢501室-4</p>
                <p>邮编：310000</p>
            </div>
            <div className={style.channel}>
                <h5>新闻资讯</h5>
                {
                    newList.map(item=> <p key={item.id} onClick={v => toDetails(item.id)}>{item.title}</p>)
                }
            </div>
            <div className={style.solution}>
                <h5>经典案例</h5>
                <p onClick={toCaseStudy}>企业贷</p>
                <p onClick={toCaseStudy}>信用贷</p>
                <p onClick={toCaseStudy}>房抵贷</p>
                <p onClick={toCaseStudy}>车抵贷</p>
            </div>
            <div className={style.about}>
                <h5>关于浙理融</h5>
                <p onClick={toAbout}>关于我们</p>
                <p onClick={toForm}>加入我们</p>
                <p onClick={toAbout}>联系我们</p>
            </div>
            <div className={style.erweima}>
                <h5>浙理融</h5>
                <img src={require('./imgs/erweima.png')} alt="" />
            </div>
        </div>

        <div className={style.beian}>
            <img style={{marginRight: '5px'}} src={require('./imgs/beian.png')} alt="" />
            <a target="_blank" rel="noreferrer" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010202002296" style={{textDecoration:'none'}}>
                浙公网安备 33010202002296号
            </a> 
            <a style={{textDecoration:'none'}} href="https://beian.miit.gov.cn/"  target="_blank" rel="noreferrer">
                {/* 已修改 */}
                浙ICP备19046455号-5
            </a> 
            杭州浙理融网络科技有限公司
        </div>
        <p className={style.text}>贷款有风险，选择须谨慎</p>
    </div>
  )
}
